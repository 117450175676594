import React from "react";
import "./FooterNew.css"

const FooterNew = () => {

    return(
        <div className="footer-main-container">
            <div className="footer-upper-box">
                <div className="footer-logo-box">
                    <img src="images/HSUP_horizontal_rgb_logo-02.png" width="320px" height="auto"></img>
                    <div className="footer-upper-desc">Free and open platform for startups and innovators.</div>
                </div>
                <div className="footer-links">
                    <h3>TOOLS</h3>
                    <div><a href="/startups">Create your startup page</a></div>
                    <div><a href="/events">Apply for events</a></div>
                </div>
                <div className="footer-links">
                    <h3>OUR COMPANY</h3>
                    <div><a href="/organizations/hsup">HSUP</a></div>
                    <div><a href="mailto:info.hsup@niu.hu">Partnerships</a></div>
                    <div><a href="mailto:info.hsup@niu.hu">Media</a></div>
                </div>
            </div>
            <div className="footer-bottom-box">
                <div className="footer-bottom-box-desc">
                © Pozi Network Plc. All rights reserved.
                </div>
                <img src="/images/niu-logo-white.svg" width="100%" height="auto"></img>
                    <img src="/images/nkfih-rgba-white.png" width="auto" height="auto"></img>
                <div className="footer-social-icons">
                    <a href="https://www.facebook.com/HungarianStartupUniversityProgram"><img src="/images/social_icons/white/Facebook.svg"></img></a>
                    <a href="https://www.youtube.com/user/InnovaciosHivatal"><img src="/images/social_icons/YouTube.svg"></img></a>
                    <a href="https://hsup.nkfih.gov.hu/"><img src="/images/social_icons/white/www.svg"></img></a>
                </div>
            </div>
        </div>
    )
}

export default FooterNew;