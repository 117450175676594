import React , {useState, useEffect, useContext} from 'react'
import './HeaderV2.css';
import {Link, NavLink, useLocation} from 'react-router-dom';
import { user } from '../../libs/utils/user';
import { Badge, Icon, notification } from 'antd';
import { get } from '../../libs/utils/request';
import  Notifications  from '../Notifications/Notifications';
import AuthContext from '../../../store/AuthContext'
import UserContext  from '../../../store/UserContext';

interface Props {
    hamburgerMenuIsOpen: boolean;
    onOpen: (hamburgerMenuIsOpen: boolean) => void;
    notification: (param: boolean) => void;
    notificationParam: boolean
}

export default function HeaderV2({hamburgerMenuIsOpen, onOpen, notification, notificationParam}:Props) {
    const [messageCount, setMessageCount] = useState(0)
    const [notifications, setNotifications] = useState([])
    const [notificationCount, setNotificationCount] = useState(0);
    const [noGroups, setNoGroups] = useState(false)
    const authContext = useContext(AuthContext);
    const userCtx = useContext(UserContext)
    const location = useLocation()

    let timer:any = null

    const isActive = userCtx.user && userCtx.user.active;

    useEffect(()=> {
        onOpen(false)
    },[location])
   
    

    useEffect(() => {
        loader();
        (process.env.REACT_APP_HOST_ENV === 'live' 
        || process.env.REACT_APP_HOST_ENV === 'demo' //testing notifications and messageCount on demo
        )
         && startTimer();
      }, []);
      

    const loader = async ()  => {
        if(user()?.active || user()?.trial)
            try {
                 await get('/ping').then((res:any)=> {
                     setNotifications(res.result);
                     setNotificationCount(res.notificationCount);
                     setMessageCount(res.messageCount);
                    
                });
              } catch (e) {
                console.error(e);
              }
        };
    const startTimer = () => {
        if (timer !== null) {
          clearInterval(timer);
        }
        timer = setInterval(() => {
        loader();
        }, 15000);
      }

      let trialEnded = false;
    
      if(window.location.href.includes('trial-ended')){
          trialEnded = true;
      }
    
    
    
    return (
        
        <>
        <div className='header-navbar-container'>
            <div className='navbar'>
               <div className='nav-links'>
                   <NavLink className="main-logo" to={trialEnded ? '/trial-ended' : "/"}>
                     <img src="/images/navbar-logo-wo-version.svg"/>
                   </NavLink>
                   <NavLink className="hsup-logo" to="https://hsup.nkfih.gov.hu/" target="_blank">
                        <img src="/images/HSUP_horizontal_rgb_logo-02.png" width="160px" height="auto" />
                   </NavLink>

                   {
                       isActive && <><NavLink
                       to='/dashboard'
                       className='Header-navlink'
                       activeClassName='Header-navlink-selected'
                        >
                       Dashboard
                        </NavLink> 
                        <NavLink
                        to={trialEnded ? '/trial-ended':'/ideas'}
                        className='Header-navlink'
                        activeClassName='Header-navlink-selected'
                        >
                        Ideas
                       </NavLink>
                     
                       <NavLink
                           to={trialEnded ? '/trial-ended' : '/startups'}
                           className='Header-navlink'
                           activeClassName='Header-navlink-selected'
                       >
                       Startups
                       </NavLink>
                       <NavLink
                           to={trialEnded ? '/trial-ended' : '/organizations'}
                           className='Header-navlink'
                           activeClassName='Header-navlink-selected'
                       >
                       Organizations
                       </NavLink>
                       <NavLink
                           to={trialEnded ? '/trial-ended' : '/competitions'}
                           className='Header-navlink'
                           activeClassName='Header-navlink-selected'
                       >
                       Competitions
                       </NavLink>
                       <NavLink
                           to={trialEnded ? '/trial-ended' : '/events'}
                           className='Header-navlink'
                           activeClassName='Header-navlink-selected'
                       >
                       Events
                       </NavLink>
                                </>

                   }
                    {
                        isActive &&
                        <NavLink
                        to='/users'
                        className='Header-navlink'
                        activeClassName='Header-navlink-selected'
                    >
                    Users
                    </NavLink>

                    }
                    {
                        isActive &&
                        <NavLink
                        to='/invitations'
                        className='Header-navlink'
                        activeClassName='Header-navlink-selected'
                        >
                        Invitations
                        </NavLink>
                    }
                    {
                        isActive && noGroups &&
                        <NavLink
                        to='/groups'
                        className='Header-navlink'
                        activeClassName='Header-navlink-selected'
                        >
                        Groups
                        </NavLink>
                    }
                   
                
                </div>
                {
                    userCtx.user ? (
                        <div className='header-user-info'>
                            <div className='header-alerts'>
                                <NavLink to="/messages">
                                    <div className='header-notification'>
                                        <Badge count={messageCount} style={{ fontSize: '12px', backgroundColor: 'rgb(226,49,92)' }}>
                                            <div style={{ marginRight: '5px' }}> 
                                                <img src="/images/header-message.svg" alt=''/>
                                            </div>
                                        </Badge>
                                    </div>
                                </NavLink>
                                <Notifications notifications={notifications} notificationCount={notificationCount}/>
                            </div>
                        <div className='Header-user' key='user'>
                                
                                <Link
                                to='/profile'
                                className='header-user-name'
                                >
                                    <img src={userCtx.user.profileImage || '/images/user.svg'} alt='' />
                                <p className='hey'>Hey <span>{userCtx.user.firstName}!</span> </p>
                                </Link>
                        </div>
                        <div  className='logout'>
                            <Icon  className='logout-icon' onClick={()=> authContext.logout()}  type='logout' />
                        </div>
                </div>
                    ):(
                        <NavLink to="/login">
                            <div className='Login-route'>Log in</div>
                        </NavLink>
                    )
                }

                <div className='hamburger-menu' onClick={()=> onOpen(true)}>
                        <img src="/images/hamburger-menu.svg"/>
                </div>
            </div>
            <div className={hamburgerMenuIsOpen ? "side-bar" : "side-bar-inactive"}>
                <div className='side-bar-container'>
                    <div className='sidebar-content'>
                        <div className='close-sidebar' onClick={()=> onOpen(false)}>
                            <img className='close-icon' src="/images/close_black.svg"/>
                        </div>
                        {
                            userCtx.user &&
                            <div className='sidebar-user-links'>
                            <div className='Header-user' key='user'>
                                    <div className='sidebar-profile'>
                                        <Link
                                        to='/profile'
                                        className='sidebar-user-name'
                                        >
                                            <div className='username-container'>
                                            <img className='sidebar-profile-pic' src={userCtx.user.profileImage || '/images/user.svg'} alt='' />

                                                <div>Hey</div>
                                                <div className='my-user-firstname'>{userCtx.user.firstName}</div>
                                            </div>
                                        </Link>
                                    </div>
                            </div>
                            <a href='/messages' className='sidebar-notification'>
                                <NavLink to="/messages">
                                        <Badge count={messageCount} style={{ fontSize: '12px', backgroundColor: 'rgb(226,49,92)' }}>
                                            <img  className='sidebar-icons' src="/images/header-message.svg" alt=''/>
                                        </Badge>
                                </NavLink>
                                <div className='sidebar-text'>Messages</div>
                            </a>
                            <div className='sidebar-message' onClick={()=> notification(!notificationParam) }>
                                <Notifications  type="white" notifications={notifications} notificationCount={notificationCount}/>
                            </div>
                        </div>
                        }
                        
                        <div className='side-route-links'>
                            {
                                isActive &&
                                <>
                                <NavLink
                                to='/dashboard'
                                className='sidebar-navlink'
                                activeClassName='sidebar-navlink-selected'
                            >
                            Dashboard
                            </NavLink>
                          
                            <NavLink
                                to={trialEnded ? '/trial-ended' : '/ideas'}
                                className='sidebar-navlink'
                                activeClassName='sidebar-navlink-selected'
                            >
                            Ideas
                            </NavLink>
                            <NavLink
                                to={trialEnded ? '/trial-ended' : '/startups'}
                                className='sidebar-navlink'
                                activeClassName='sidebar-navlink-selected'
                            >
                            Startups
                            </NavLink>
                            <NavLink
                                to={trialEnded ? '/trial-ended' : '/organizations'}
                                className='sidebar-navlink'
                                activeClassName='sidebar-navlink-selected'
                            >
                            Organizations
                            </NavLink>
                            <NavLink
                                to={trialEnded ? '/trial-ended' : '/competitions'}
                                className='sidebar-navlink'
                                activeClassName='sidebar-navlink-selected'
                            >
                            Competitions
                            </NavLink>
                            <NavLink
                                to={trialEnded ? '/trial-ended' : '/events'}
                                className='sidebar-navlink'
                                activeClassName='sidebar-navlink-selected'
                            >
                            Events
                            </NavLink>
                            </>
                            }
                            {
                                isActive &&
                                    <NavLink
                                    to='/users'
                                    className='sidebar-navlink'
                                    activeClassName='sidebar-navlink-selected'
                                    >
                                    Users
                                    </NavLink>
                            }
                            {
                                isActive &&
                                <NavLink
                                to='/invitations'
                                className='sidebar-navlink'
                                activeClassName='sidebar-navlink-selected'
                                >
                                Invitations
                                </NavLink>
                            }
                           {
                               isActive && noGroups &&
                               <NavLink
                               to='/groups'
                               className='sidebar-navlink'
                               activeClassName='sidebar-navlink-selected'
                                >
                                Groups
                                </NavLink>

                           }
                           
                            {
                               userCtx.user ? 
                                ( 
                                <div className='sidebar-logout' aria-hidden='true' onClick={() => authContext.logout()}>
                                <Icon className='sidebar-logout' type='logout' />
                                <span>Logout</span>
                                 </div>
                                 )
                                :
                                (
                                 <NavLink to="/login">
                                     <div className='sidebar-login'>Log in</div>
                                 </NavLink>
                                )
                            }
                           
                        </div>
                </div>
                </div>
            </div>
        </div>
       
        </>
    )
}
