import { Carousel, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { get, post } from "../../libs/utils/request";
import { user } from "../../libs/utils/user";
import { EventCard, HomePageEventCard } from "../CardView/ProjectCard";
import RegisterWallModal from "../common/RegiserWallModal";
import Slider from "../Sales/Slider";
import TestimonialCard from "../Sales/TestimonialCard";
import "./HomePage.css";
import { FrontendDataResponse } from "./IndexPage2";

const dataForSlider = [
  {
    img: "/images/index_page/1business-people-laptop-overlay.png",
    titleup: "HSUP Matchmaking & Alumni Platform",
    title: "Stand Out from the Crowd!",
    description: "Welcome to the website of the first online university startup program!",
    button: user() ? "Show the dashboard" : "Sign Up Here!",
  },
  {
    img: "/images/index_page/1business-people-laptop-overlay.png",
    titleup: "HSUP Matchmaking & Alumni Platform",
    title: "Stand Out from the Crowd!",
    description: "Welcome to the website of the first online university startup program!",
    button: user() ? "Show the dashboard" : "Sign Up Here!",
  },
];

const carouselResponsiveSettings = [
  {
    breakpoint: 1850,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1625,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1422,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1219,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1166,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1016,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];
let enter: any;
let dateExpired = false;

const HomePage = () => {
  const [frontendData, setFrontendData] = useState<FrontendDataResponse>({
    numbers: {
      ideas: 0,
      projects: 0,
      users: 0,
    },
    latestNews: [],
    mentors: [],
    events: [],
    topLists: {
      ideas: [],
      mentors: [],
      projects: [],
    },
  });
  const [email, setEmail] = useState("");
  const [events, setEvents] = useState([]);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // setTimeout(() => {
  //   if (!sessionStorage.getItem("subscriptionClosed")) {
  //     setShowSubscriptionModal(true);
  //   }
  // }, 1 * 6000);
  // // , 5*60000);

  useEffect(() => {
    (async () => {
      try {
        const data: FrontendDataResponse = await get("/frontpage/");

        const event = await get<{ data: any[]; pagination: any }>("events", {
          currentPage: 1,
          limit: 4,
        });
        setEvents(event.data.reverse());
        setFrontendData(data);
      } catch (error) {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.extendedUser
        ) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(error.response.data.extendedUser)
          );
          if (
            !error?.response?.data?.extendedUser?.active &&
            !error?.response?.data?.extendedUser?.trial
          ) {
            window.location.replace("/trial-ended");
          }
        }
        message.error("Failed to fetch data.");
      }
    })();
  }, []);

  const sendSubscription = async () => {
    await post("/subscription", { email })
      .then((response) => {
        message.success(response, 5);
        let text = ((
          document.getElementById(
            "homepage-email-subscribe-input"
          ) as HTMLInputElement
        ).value = "");
        // sessionStorage.setItem("subscriptionClosed", "true");
        // setShowSubscriptionModal(false);
      })
      .catch((e) => {
        message.success("Subscribe failed", 5);
        message.error(e.response?.data?.message || "Network error");
      });
  };
  const getEvents = () => {
    async () => {
      try {
        const event = await get<{ data: any[]; pagination: any }>("events", {
          currentPage: 1,
          limit: 4,
        });
        setEvents(event.data.reverse());
      } catch (error) {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.extendedUser
        ) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(error.response.data.extendedUser)
          );
          if (
            !error?.response?.data?.extendedUser?.active &&
            !error?.response?.data?.extendedUser?.trial
          ) {
            window.location.replace("/trial-ended");
          }
        }
        message.error("Failed to fetch data.");
      }
    };
  };

  const scrollTo = () => {
    var scrollDiv = document.getElementById("homepage-embed-video").offsetTop;
    window.scrollTo({ top: scrollDiv - 50, behavior: "smooth" });
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const CardView = () => {
    return dataForSlider.map((data) => (
      <div>
        <div className="homepage-text-on-image-titleup">
          {data.titleup}
        </div>
        <div className="homepage-text-on-image-first">

          <img src={data.img} className="homepage-text-on-image-desktop" />
          <div>
            <div className="homepage-text-on-image-title">{data.title}</div>
            <div className="homepage-text-on-image-description">
              {data.description}
            </div>
            <button
              type="button"
              className="ant-btn ant-btn-primary home-page-first-box-button"
            >
              {user() ? (
                <div onClick={() => window.location.replace("/dashboard")}>
                  {data.button}
                </div>
              ) : (
                <div onClick={() => setShowRegisterModal(true)}>
                  {data.button}
                </div>
              )}
            </button>
          </div>
          </div>
        </div>
    ));
  };

  return (
    <div className="home-page-main-container">
      <Carousel
        className="home-page-image-slider"
        slidesToShow={1}
        infinite={true}
        // autoplay
        // autoplaySpeed={2500}
        responsive={carouselResponsiveSettings}
      >
        {CardView()}
      </Carousel>

      <div className="home-page-counters-title">
        <div className="home-page-counters">
          <div>
            <img
              src="/images/index_page/users.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.users}
            </span>
            Users registered
          </div>
          <div className="homepage-counter-center-box">
            <img
              src="/images/index_page/ideas.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.ideas}
            </span>
            Ideas created
          </div>
          <div>
            <img
              src="/images/index_page/startups.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.projects}
            </span>
            Startups created
          </div>
        </div>
      </div>
      <div className="home-page-counters-title-box">
        <div className="home-page-counters-title-box-title">
          From Classroom to Startup
        </div>
        <div className="home-page-counters-title-box-description">
          <div className="home-page-counters-title-box-text">
            HSUP aims to introduce Hungarian university students to innovation, entrepreneurship, and startups through a shared educational platform.</div>
        </div>
      </div>
      <div className="home-page-helping-startups-container">
        <div className="home-page-helping-startups-title">
          Why Choose HSUP?
        </div>
        <div className="home-page-helping-startups-desc">
        We believe that everyone has creativity within them and the potential to innovate in their own field of interest. Whether you're an engineer, economist, humanities major, doctor, or lawyer, you’re already improving things in your daily life—maybe without even realizing it. Now it's time to learn how to bring your ideas to life!
        </div>
      </div>
      <div className="home-page-join-startup-section" id="homepage-embed-video">
        <div className="home-page-join-startup-section-one">
          <div className="homepage-embed-video" id="homepage-embed-video">
            <iframe
              src="https://www.youtube.com/embed/Ae_duthFBlw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="home-page-join-startup-section-two">
          <span className="home-page-join-startup-section-two-title">
            Learn with Us—The HSUP Way!
            <br />
            <br />
          </span>
          <span className="home-page-join-startup-section-two-description">
            <b>Characters:</b> Entrepreneurship is the board game of adulthood. The role you play within a business depends on a variety of factors. At HSUP, the wand chooses the wizard! The program begins with a role-assignment test, helping you discover which of the four key startup founder roles—CEO (Chief Executive Officer), CTO (Chief Technology Officer), Project Manager, or Designer—best fits your skills and interests.
            <br />
            <br />
          </span>
        </div>
      </div>
      <div>
      <img
          src="/images/index_page/FB_cover_1.png"
          className="homepage-text-on-image-desktop"
        />
        <img
          src="/images/index_page/FB_cover_1.png"
          className="homepage-text-on-image-mobile"
        />
      </div>

      <div className="home-page-events">
        <div className="home-page-events-title">
          Stay tuned for our next events
        </div>
        <div className="home-page-events-cards">
          {events.map((event) => (
            <HomePageEventCard event={event} onChange={() => getEvents()} />
          ))}
        </div>
      </div>

      <RegisterWallModal
        onCancel={() => setShowRegisterModal(false)}
        visible={showRegisterModal}
        title="Sign up for Pozi"
      />
    </div>
  );
};

export default HomePage;
